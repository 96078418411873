const it = {
  curLang: "Italiano",
  homepage: "Home",
  card: "Carta",
  list: "Lista",
  close: "Chiudere",
  create: "Creare",
  update: "Aggiornare",
  confirm: "Confermare",

  "role-1": "Proprietario",
  "role-3": "Manager",
  "role-5": "Personale",
  "role-101": "Capo",
  "role-105": "Lavoratore",

  "navLabel-users": "Utenti",
  "navLabel-user": "Utente",
  "navLabel-shops": "Negozi",
  "navLabel-shop": "Negozio",
  "navLabel-orders": "Ordini",
  "navLabel-order": "Ordine",
  "navLabel-pds": "Prodotti",
  "navLabel-pd": "Prodotto",
  "navLabel-prods": "Merce",
  "navLabel-prod": "Merce",
  "navLabel-categs": "Categorie",
  "navLabel-categ": "Categoria",
  "navLabel-brands": "Marche",
  "navLabel-brand": "Marca",
  "navLabel-clients": "Clienti",
  "navLabel-client": "Cliente",
  "navLabel-setting": "Impostazioni",
  "navLabel-areas": "Zone",
  "navLabel-citas": "Città Listino",
  "navLabel-cita": "Città",
  "navLabel-back": "< Indietro",
  //nabLabelAdd
  "navLabelAdd-user": "Aggiungi Utente",
  "navLabelAdd-shop": "Aggiungi Negozio",
  "navLabelAdd-pd": "Aggiungi Merce",
  "navLabelAdd-prod": "Aggiungi Merce",
  "navLabelAdd-brand": "Aggiungi Marca",
  "navLabelAdd-categ": "Aggiungi Categoria",

  "welcom-title": "Benvenuto a  Union City Manage System",
  "welcom-code": "Il suo codice è ",
  "welcom-role": "La sua identità è ",
  "welcom-warning": "Connessione al server",

  "switchLabel-basic": "Generale",
  "switchLabel-servCita": "Città",
  "switchLabel-prods": "Sincronizza",
  "switchLabel-attr&sku": "Proprietà&SKU",
  "switchLabel-history": "Registro",
  "switchLabel-purchased": "Acquitati",

  "infoSection-shopImg": "Immagine Negozio",
  "infoSection-shopInfo": "Informazione Negozio",

  // input
  "inputLabel-code": "Codice",
  "inputLabel-name": "Nome",
  "inputLabel-addr": "Indirizzo",
  "inputLabel-city": "Città",
  "inputLabel-zip": "codice ZIP",
  "inputLabel-phone": "Cellulare",
  "inputLabel-is_usable": "USABILE",
  "inputLabel-pwd": "Password",
  "inputLabel-priceShip": "Costo di consegna",
  "inputLabel-role": "Ruolo",
  "inputLabel-shop": "Negozio",
  "inputLabel-lastLogin": "Ultimo login",

  //btns
  "btnLabel-edit": "Modifica",
  "btnLabel-cancel": "Cancella",
  "btnLabel-submit": "Conferma",
  "btnLabel-delete": "Elimina",
  "btnLabel-newServCita": "Aggiungi Città",
  "btnLabel-editPass": "Modifica Password",

  //settings
  "settingCard-company": "Azienda",
  "settingCard-nation": "Paese",
  "settingCard-area": "Zona",
  "settingCard-city": "Città",

  "LangUpdModal-title": "Cambia Lingua",
};
export default it;
