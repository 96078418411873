const en_US = {
  curLang: "English",
  homepage: "Home",
  card: "Card",
  list: "List",
  close: "Close",
  create: "Create",
  update: "Update",
  confirm: "Confirm",

  "role-1": "Owner",
  "role-3": "Manager",
  "role-5": "Staff",
  "role-101": "Boss",
  "role-105": "Woker",

  "navLabel-users": "Users",
  "navLabel-user": "User",
  "navLabel-shops": "Shops",
  "navLabel-shop": "Shop",
  "navLabel-orders": "Orders",
  "navLabel-order": "Order",
  "navLabel-pds": "Pds",
  "navLabel-pd": "Pd",
  "navLabel-prods": "Prods",
  "navLabel-prod": "Prod",
  "navLabel-categs": "Categs",
  "navLabel-categ": "Categ",
  "navLabel-brands": "Brands",
  "navLabel-brand": "Brand",
  "navLabel-clients": "Clients",
  "navLabel-client": "Client",
  "navLabel-setting": "Setting",
  "navLabel-areas": "Areas",
  "navLabel-citas": "Cities",
  "navLabel-cita": "City",
  "navLabel-back": "< Return",
  //nabLabelAdd
  "navLabelAdd-user": "Add User",
  "navLabelAdd-shop": "Add SHop",
  "navLabelAdd-pd": "Add product",
  "navLabelAdd-prod": "Add product",
  "navLabelAdd-brand": "Add brand",
  "navLabelAdd-categ": "Add category",

  "welcom-title": "Welcome to Union City manage System",
  "welcom-code": " Your code is ",
  "welcom-role": "Your Identity is ",
  "welcom-warning": "Connecting to server",

  "switchLabel-basic": "Basic",
  "switchLabel-servCita": "City",
  "switchLabel-prods": "Sync",
  "switchLabel-attr&sku": "Attributes&SKU",
  "switchLabel-history": "History",
  "switchLabel-purchased": "Purchased",

  "infoSection-shopImg": "Shop Image",
  "infoSection-shopInfo": "Shop information",

  // input
  "inputLabel-code": "Code",
  "inputLabel-name": "Name",
  "inputLabel-addr": "Adress",
  "inputLabel-city": "City",
  "inputLabel-zip": "ZIP code",
  "inputLabel-phone": "Phone",
  "inputLabel-is_usable": "USABLE",
  "inputLabel-pwd": "Password",
  "inputLabel-priceShip": "Shipping cost",
  "inputLabel-role": "Role",
  "inputLabel-shop": "Shop",
  "inputLabel-lastLogin": "Last login",

  //btns
  "btnLabel-edit": "Edit",
  "btnLabel-cancel": "Cancel",
  "btnLabel-submit": "Confirm",
  "btnLabel-delete": "Delete",
  "btnLabel-newServCita": "Add City",
  "btnLabel-editPass": "Change password",

  //settings
  "settingCard-company": "Company",
  "settingCard-nation": "Country",
  "settingCard-area": "Area",
  "settingCard-city": "City",

  "LangUpdModal-title": "Change Language",
};
export default en_US;
