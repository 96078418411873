const zh_CN = {
  curLang: "中文",
  homepage: "主页",
  card: "卡片",
  list: "列表",
  close: "关闭",
  create: "创建",
  update: "更新",
  confirm: "确认",

  "role-1": "拥有者",
  "role-3": "管理者",
  "role-5": "超级员工",
  "role-101": "店铺老板",
  "role-105": "店铺员工",

  "navLabel-users": "用户列表",
  "navLabel-user": "用户",
  "navLabel-shops": "店铺列表",
  "navLabel-shop": "店铺",
  "navLabel-orders": "订单列表",
  "navLabel-order": "订单",
  "navLabel-pds": "产品列表",
  "navLabel-pd": "产品",
  "navLabel-prods": "商品列表",
  "navLabel-prod": "商品",
  "navLabel-categs": "分类列表",
  "navLabel-categ": "分类",
  "navLabel-brands": "品牌列表",
  "navLabel-brand": "品牌",
  "navLabel-clients": "客户列表",
  "navLabel-client": "客户",
  "navLabel-setting": "设置",
  "navLabel-nations": "国家列表",
  "navLabel-areas": "大区列表",
  "navLabel-citas": "城市列表",
  "navLabel-back": "< 返回",
  //nabLabelAdd
  "navLabelAdd-user": "添加用户",
  "navLabelAdd-shop": "添加店铺",
  "navLabelAdd-pd": "添加产品",
  "navLabelAdd-prod": "添加商品",
  "navLabelAdd-brand": "添加品牌",
  "navLabelAdd-categ": "添加分类",

  "welcom-title": "欢迎来到 Union City 管理系统",
  "welcom-code": " 您的账号是 ",
  "welcom-role": "您的身份是 ",
  "welcom-warning": "正在连接服务器",

  "switchLabel-basic": "基础信息",
  "switchLabel-servCita": "服务城市",
  "switchLabel-prods": "同步商品",
  "switchLabel-attr&sku": "属性&SKU",
  "switchLabel-history": "历史订单",
  "switchLabel-purchased": "已购商品",

  "infoSection-shopImg": "店铺图片",
  "infoSection-shopInfo": "店铺信息",

  // input
  "inputLabel-code": "编号",
  "inputLabel-name": "名称",
  "inputLabel-addr": "地址",
  "inputLabel-city": "城市",
  "inputLabel-zip": "邮编",
  "inputLabel-email": "邮箱",
  "inputLabel-phone": "电话",
  "inputLabel-is_usable": "是否可用",
  "inputLabel-pwd": "密码",
  "inputLabel-priceShip": "运费",
  "inputLabel-role": "职位",
  "inputLabel-shop": "门店",
  "inputLabel-lastLogin": "最近登录",

  //btns
  "btnLabel-edit": "编辑",
  "btnLabel-cancel": "取消",
  "btnLabel-submit": "确认",
  "btnLabel-delete": "删除",
  "btnLabel-editPass": "修改密码",

  //settings
  "settingCard-company": "公司",
  "settingCard-nation": "国家",
  "settingCard-area": "大区",
  "settingCard-city": "城市",

  "btnLabel-newServCita": "添加新服务城市",

  "LangUpdModal-title": "语言变更",
};
export default zh_CN;
